<template>
  <router-view/>
</template>

<script>
import * as NoSleep from 'nosleep.js/dist/NoSleep';

export default {
  data() {
    return {
      noSleep: new NoSleep(),
    };
  },
  mounted() {
    this.noSleep.enable();
  },
};
</script>
<style lang="scss">

$color-black: #000;
$color-gray-0: #111;
$color-gray-1: #333;
$color-gray-2: #707070;
$color-gray-3: #ccc;
$color-white: #fff;

$font-size-smallest: 1rem;
$font-size-smaller: 1.5rem;
$font-size-medium: 2.5rem;
$font-size-larger: 3.5rem;
$font-size-largest: 4rem;

html {
  background-color: $color-black;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-gray-2;
  background-color: $color-black;
}

#exercises::-webkit-scrollbar {
  display: none;
}

#current-workout-stats {
  grid-area: main-top;
  display: block;
}
#current-workout-options {
  grid-area: main-bot;
  display: block;
}
#exercises {
  grid-area: side;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 800px;
}
#ready-exercises {
  grid-area: side-ready;
  background-color: $color-gray-0;
}
.ace_scrollbar {
  display: none;
}

#resting-exercises {
  grid-area: side-rest;
  border-top: 2px solid $color-gray-2;
}
#ready-text {
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  height: calc(80% - 144px);
  top: 72px;
  z-index: 1000;
}
#errors-text {
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  height: calc(100% - calc(80% - 72px));
  top: calc(80% - 72px);
  z-index: 1000;
}
#ready-up {
  cursor: pointer;
  top: 0px;
  left: 0px;
  height: 72px;
  width: 100%;
  z-index: 1001;
  font-size: $font-size-smaller;
}
#run-workout {
  grid-area: start;
  padding-top: 20px;
  height: 18%;

  button:hover {
    background-color: $color-gray-0;
  }
}

#video {
  grid-area: main-mid;
  display: block;
  background-color: $color-gray-1;
  margin: auto;
  margin-bottom: 50px;
  width: 100%;
}

h1 {
  font-size: $font-size-smaller;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: normal;
}

h2 {
  margin-top: 0px;
  font-size: $font-size-smaller;
}

h3 {
  font-size: $font-size-smaller;
  padding-top: 20px;
}

input {
  border-radius: 40px;
  border-style: solid;
  font-size: $font-size-smaller;
  padding: 10px;
  width: 50px;
  margin-left: 30px;
  background-color: $color-gray-3;
}

.h2-1 {
  font-weight: normal;
}

.card {
  grid-template-areas:
    "left-header right-header exit"
    "left-sub-header right-sub-header side-bar"
    "line line line"
    "button-zone button-zone button-zone";
  grid-template-columns: 50% 30% 20%;
  display: grid;
  .exit {
    grid-area: exit;
    text-align: right;
    padding-right: 20px;
    padding-top: 20px;
    cursor: pointer;
  }
  .header {
    &.left {
      grid-area: left-header;
    }
    &.right {
      grid-area: right-header;
      text-align: center;
    }
    text-align: left;
    font-size: $font-size-smaller;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .line {
    grid-area: line;
    border-bottom: solid 1px #444;
  }
  .sub-header {
    &.left {
      grid-area: left-sub-header;
    }
    &.right {
      grid-area: right-sub-header;
      text-align: center;
    }
    display: block;
    font-size: $font-size-smallest;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 20px;
    text-transform: capitalize;
  }
  .side-bar {
    display: flex;
    align-items: center;
    justify-content: right;
    grid-area: side-bar;
    text-align: right;
    height: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .button-zone {
    display: flex;
    padding: 0;
    margin: 0;
    margin-bottom: 0px;
    grid-area: button-zone;
    width: 100%;
    justify-content: space-evenly;
    background-color: #222;

    button {
      // width: 33.334%;
      margin: 0;
      border-radius: 0;
      font-size: $font-size-smallest;
      text-align: center;
      width: 25%;
      padding: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color:#222;
      border-width: 1px;
      border-color: $color-gray-1;
      border-top-width: 0px;
      border-bottom-width: 0px;
    }

    button:hover {
      background-color: $color-gray-2;
    }
  }
}

.overlay-editor {
  height: 100%;
  width: 100%;
  background-color: $color-black;
}

button.overlay {
  background-color: $color-black;
  border: none;
  color: $color-gray-2;
  padding: 10px;
  position: fixed;
}

.ready-exercise {
  margin-bottom: 20px;
  background-color: $color-gray-1;
}

.workout-zone {
  button {
    // width: 190px;
    padding: 30px;
    border-radius: 40px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-style: solid;
    font-size: $font-size-smaller;
    color: $color-gray-3;
    background-color: $color-black;
    cursor: pointer;
  }
  button.stop:hover {
    color: $color-white;
  }

  button.stop {
    background-color: $color-gray-1;
    border-radius: 100px;
    border: none;
    padding: 0;
  }
  display: grid;
  grid-template-areas:
    "header"
    "main-mid"
    "main-top"
    "main-bot"
    "side"
    "start";
}

@media only screen and (min-width: 1300px) {
/* For Desktop: */
#video {
  margin-bottom: 50px;
  width: 900px;
  height: 500px;
}

h1 {
  font-size: $font-size-largest;
}

h2 {
  margin-top: 0px;
  font-size: $font-size-larger;
}

input {
  border-radius: 40px;
  border-style: solid;
  font-size: $font-size-larger;
  padding: 20px;
  width: 70px;
  margin-left: 30px;
  background-color: $color-gray-3;
}

.workout-zone {
  grid-template-areas:
    "header start"
    "main-top side"
    "main-mid side"
    "main-bot side";
  grid-template-columns: 70% 30%;
}
/* End-For Desktop */
}

</style>
